<template>
    <div>
        <b-overlay :show="state.loading">
            <b-card no-body class="p-1">
                <b-form class="d-flex flex-column" @submit.prevent="submit">
                    <b-form-group label="محتوی درباره ما">
                        <froala v-model="formData.content" :config="config"/>
                    </b-form-group>
                    <b-button variant="primary" type="submit">ذخیره</b-button>
                </b-form>
                <b-form-group class="p-1 m-1 border" label="فایل راهنما">
                    <froalaView v-model="formData.content" :config="config"/>
                </b-form-group>
            </b-card>
            <b-card no-body class="p-1">
                <b-form class="d-flex flex-column" @submit.prevent="uploadImage">
                    <b-form-group label="ارسال تصویر همکار">
                        <b-form-file v-model="uploadData.file"
                                     :state="Boolean(uploadData.file)"
                                     placeholder="Choose a file or drop it here..."
                                     drop-placeholder="Drop file here..."
                        />
                    </b-form-group>
                    <b-form-group label="نام">
                        <b-form-input v-model="uploadData.name"/>
                    </b-form-group>
                    <b-form-group label="وظیفه">
                        <b-form-input v-model="uploadData.role"/>
                    </b-form-group>
                    <b-button variant="primary" type="submit">ذخیره</b-button>
                </b-form>
            </b-card>
            <b-card no-body class="p-1">
                <b-row>
                    <b-col class="mb-2" sm="6" md="4" v-for="coWorker in coWorkers" :key="coWorker.id">
                        <b-card
                                :title="coWorker.name"
                                :img-src="coWorker.image"
                                img-alt="Image"
                                img-top
                                tag="article"
                                style="max-width: 20rem; border: 1px solid blue"
                                class="h-100"
                        >
                            <b-card-text>{{ coWorker.role }}</b-card-text>
                            <br/>
                            <b-button @click.prevent="removeCoworker(coWorker.id)" variant="primary">حذف</b-button>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import {
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    // BInputGroupPrepend,
    BOverlay,
    BFormFile,
    BCol,
    BRow,
    BCardText
} from 'bootstrap-vue'

export default {
    name: "AboutUs",
    components: {
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BFormFile,
        // BInputGroupPrepend,
        BCol,
        BRow,
        BOverlay,
        BCardText
    },
    data: () => ({
        isFile: 'editor',
        formData: {
            id: '',
            title: '',
            content: '',
            name: 'ABOUT_US'
        },
        uploadData: {
            name: "",
            file: null,
            role: ""
        },
        config: {
            events: {
                'initialized': function () {
                    console.log('floara inited')
                },
                'image.beforeUpload': function (files) {
                    let editor = this;
                    if (files.length) {
                        // Create a File Reader.
                        let reader = new FileReader();
                        // Set the reader to insert images when they are loaded.
                        reader.onload = function (e) {
                            let result = e.target.result;
                            editor.image.insert(result, null, null, editor.image.get());
                        };
                        // Read image as base64.
                        reader.readAsDataURL(files[0]);
                    }
                    editor.popups.hideAll();
                    // Stop default upload chain.
                    return false;
                }
            },
            language: 'fa',
            direction: 'rtl',
            tooltips: false,
            toolbarButtons: {
                'moreText': {
                    'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                },
                'moreParagraph': {
                    'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'formatOLSimple', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                },
                'moreRich': {
                    'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR']
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo', 'fullscreen', 'pint', 'selectAll', 'html', 'help'],
                    'align': 'right',
                    'buttonsVisible': 2
                }
            },
            fontFamily: {
                'Yekan': 'یکان',
            },
            fontFamilyDefaultSelection: 'Yekan',
            fontFamilySelection: false,
            key: "1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==",
            attribution: false // to hide "Powered by Froala"
        },
        text: '',
        output: '',
        coWorkers: []
    }),
    methods: {
        async submit() {
            this.state.loading = true
            if (!this.formData.id) {
                const res = await this.$axios.post('/pages', this.formData)
                this.formData = res.data.data
                this.$error('راهنما با موفقیت ایجاد شد', '', 'success')
            } else {
                const res = await this.$axios.patch('/pages/' + this.formData.id, this.formData)
                this.formData = res.data.data
                this.$error('راهنما با موفقیت تغییر کرد', '', 'success')
            }

        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/pages?name=ABOUT_US')
            if (res.data.data && res.data.data.length > 0) {
                this.formData = res.data.data.pop()
            }
        },
        async uploadImage() {
            const url = '/about-us'
            const formData = new FormData()
            formData.append('image', this.uploadData.file)
            formData.append('name', this.uploadData.name)
            formData.append('role', this.uploadData.role)

            await this.$axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            await this.getCoWorkers()
        },
        removeCoworker(id) {
            console.log(id)
        },
        async getCoWorkers() {
            const url = '/about-us'
            let res = await this.$axios.get(url)
            this.coWorkers = res.data.data
        }
    },
    mounted() {
        this.getData()
        this.getCoWorkers()
    }

}
</script>
<style lang="scss">

.custom-file-label::after {
  content: 'انتخاب فایل' !important;
}

.fr-box {
  /*text-align: left;*/
  p, ul, li, ol {
    text-align: left;
    font-family: 'Yekan', 'IRANSans', sans-serif !important;
  }

}

</style>
